import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "../App.css";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { useFormik } from "formik";
import "./loginCss.css"
import { t } from "i18next";
import Footer from "./Footer";

const Login: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      email : '',
      password: ''
    },
    onSubmit: async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
  
        const formData = new FormData();
        formData.append("password", formik.values.password);
        formData.append("email", formik.values.email);
  
        const logindata = {
          password: formik.values.password,
          email: formik.values.email,
        };
  
        const loginResponse = await axios.post(
          "https://api.faragroupe.fr/api/login",
          logindata
        );
  
        
        const data = loginResponse.data;
        const token = data.token;
  
        if (data.ERROR) {
          alert("Connexion erronée")
          console.log(data.ERROR);
        } else {
          const userInfoResponse = await axios.get(
            "https://api.faragroupe.fr/api/me",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          localStorage.setItem(
            "user-info",
            JSON.stringify(userInfoResponse.data)
          );
  
          history({
            pathname: `/profile/${userInfoResponse.data.id}`,
          });
        }
      } catch (error) {
        alert("Connexion erronée")
      } finally {
        setLoading(false);
      }
    },
  });


  const [loading, setLoading] = useState(false);

  const history = useNavigate();




  return (
<>
  
    <video autoPlay muted loop className="video-background">
      <source
        src="https://videos.pexels.com/video-files/3127017/3127017-uhd_3840_2160_24fps.mp4"
        type="video/mp4"
      />
    </video>

  <section className="login-section">
    <div className="container">
      <div className="row justify-content-start align-items-center">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div
            className="card shadow-2-strong card-registration"
            style={{
              borderRadius: "15px",
              backgroundColor: "rgba(245, 246, 247, 0.6)",
              color: "black",
            }}
          >
            <div className="card-body p-4 p-md-5">
              <h3
                className="fw-normal mb-3 pb-3"
                style={{ letterSpacing: "1px" }}
              >
                {t("login.title")}
              </h3>
              <form onSubmit={formik.handleSubmit}>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="email"
                    name="email"
                    placeholder="example@hotmail.com"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="form-control"
                  />
                  <label className="form-label" htmlFor="form3Example3">
                    {t("login.email")}
                  </label>
                </div>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="password"
                    placeholder="●●●●●●●●●●"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    className="form-control"
                  />
                  <label className="form-label" htmlFor="form3Example4">
                    {t("login.password")}
                  </label>
                </div>
                <div className="pt-1 mb-4">
                  <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-dark btn-lg btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <RotatingLines
                        width="24"
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                      />
                    ) : (
                      t("login.login")
                    )}
                  </button>
                </div>
                <p>
                  {t("login.noAccount")}{" "}
                  <Link to="/register" className="link-info text-primary">
                    {t("login.register")}
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />

  {/* Custom CSS for responsiveness */}
  <style >{`
   .video-background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  overflow: hidden;
}

    .login-section {
      position: relative;
      z-index: 1;
    }

    @media (max-width: 768px) {
      .card-registration {
        padding: 20px;
        font-size: 0.9rem;
      }
    }

    @media (max-width: 576px) {
      .card-registration {
        padding: 15px;
        font-size: 0.8rem;
      }
    }
  `}</style>
</>


  );
};

export default Login;
