import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import DynamicNav from "./dynamicNav";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
    <DynamicNav/>
      <div
        className="container-fluid m-4 d-flex justify-content-between align-items-center bg-white rounded-2"
        style={{ width: "auto", overflow: "hidden" }}
      >
        <div
          className="justify-content-center text-center w3-animate-left"
          style={{ height: "100%", flex: "1" }}
        >
          <h1
            className="mb-3"
            style={{
              fontFamily: "cursive",
              borderBottom: "solid 1px",
            }}
          >
            {t('about.title')} 
          </h1>
          <p style={{ textAlign: "justify" }}>
            {t('about.description')} 
          </p>
        </div>
        <img
          className="img-fluid w3-animate-right"
          src="icon.jpg"
          alt={t('about.altText')}
          style={{
            width: "50%",
            height: "auto",
            objectFit: "cover",
            flexShrink: "1",
          }}
        />
      </div>

      <Footer />
    </>
  );
};

export default AboutPage;
 