import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import { t } from "i18next";

const Footer: React.FC = () => {
  return (
    <>
      <footer className="text-center text-lg-start bg-light text-muted">
        <section className="d-flex justify-content-end p-4 border-bottom">
          <div className="me-5 d-none d-lg-block mr-2">
            <span>{t("footer.follow")}</span>
          </div>
          <div>
            <a href="https://www.facebook.com/lesdouceursdumaroc" className="text-reset mr-2">
              <i className="fa fa-facebook-f "></i>
            </a>
            <a href="https://twitter.com/lesdouceursdumaroc" className="me-4 text-reset mr-2">
              <i className="fa fa-twitter"></i>
            </a>
            <a href="https://www.pinterest.com/lesdouceursduma/" className="me-4 text-reset mr-2">
              <i className="fa fa-pinterest"></i>
            </a>
            <a href="https://www.instagram.com/lesdouceursdumaroc" className="me-4 text-reset">
              <i className="fa fa-instagram "></i>
            </a>
          </div>
        </section>
        <section>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fa fa-leaf me-3"></i>Les Douceurs du Maroc
                </h6>
                <p>
{t("footer.p")}
                </p>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">{t("footer.prods")}</h6>
                <p>
                  <a href="#!" className="text-reset">Huiles Bio</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Savons Naturels</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Tisanes</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Produits pour la Peau</a>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">{t("footer.contact")}</h6>
                <p><i className="fa fa-home me-3"></i> Casablanca, Maroc</p>
                <p>
                  <i className="fa fa-envelope me-3"></i>
                  contact@lesdouceursdumaroc.com
                </p>
                <p><i className="fa fa-phone me-3"></i> +212 5 22 22 22 22</p>
                <p><i className="fa fa-print me-3"></i> +212 5 22 22 22 23</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          {t("footer.copyright")}
        </div>
      </footer>
    </>
  );
}

export default Footer;
