import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { GlobalContext } from "../App";
import "../wishlist.css";
import DynamicNav from "./dynamicNav";


const Wishlist: React.FC = () => {
  const pr  = useContext(GlobalContext).pr; 
  const favData = useContext(GlobalContext).favorites;
  
  return (
    <>
    <DynamicNav/>
    
    <div className="wishlist-page container mt-5">
      

      <div className="text-center">
        <span className="h1 text-black">
          <i className="fa-regular fa-heart"></i>
        </span>
        <h1 className="page-title mb-4 text-dark">{t("wishlist.title")}</h1>
      </div>

      <div className="table-responsive">
        <table className="table text-center">
          <thead >
            <tr>
              <th scope="col" className="h5">
                Produit
              </th>
              <th scope="col">Prix</th>
              <th scope="col">Stock status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {pr.map((product: { id: number; picture: string ; title: string;price: number; disponibilite:string }) => (
              <tr key={product.id}>
                <th scope="row">
                              <div
                                className="d-flex  flex-column flex-md-row text-md-start"
                                style={{ width: "100%" }}
                              >
                                <button className="btn btn-lg "><i className="fa fa-trash"></i> </button>
                                <img
                                  src={`https://api.faragroupe.fr/uploads/product_images/${product.picture}`}
                                  className="img-fluid rounded mr-3"
                                  style={{ width: "90px",height:"80px" }}
                                  alt={product.title}
                                />
                                <div className="d-flex flex-column ms-0 ms-md-4 mt-3 mt-md-0 align-items-start">
                                  <p className="mb-2">{product.title}</p>
                                  
                                </div>
                              </div>
                            </th>
               
                  <td>
                    <p className="mb-0">{product.price}dh</p>
  
                    </td>
                            <td>
                <span className={`badge ${product.disponibilite	 === "enstock"? "badge-success" : "badge-danger"}`}>
                  {product.disponibilite}
                </span>

                  </td>
                  <td>
                    <span>{t("wishlist.created")}</span>20/10/2024<br></br>
                    <Link to={`/details/${product.id}`}>
                      <button className="btn btn-sm btn-dark">{t("wishlist.add")}</button>
                       
                    </Link>
                    
                  </td>

              </tr>
            ))}

            
            
          </tbody>
        </table>
      </div>
    </div>

    </>
  );
};

export default Wishlist;




