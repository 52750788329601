import React, { useState } from "react";
import "../ProfileCss.css";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import DynamicNav from "./dynamicNav";
import Footer from "./Footer";
import { useFormik } from "formik";
import axios from "axios";
import { apiUrl } from "../process";

const Profile: React.FC = () => {
  const [mod, setMod] = useState(true);
  const history = useNavigate();
  const storedUserInfo = JSON.parse(localStorage.getItem("user-info") || "{}");
  const id = storedUserInfo.id;
  const prenom = storedUserInfo.firstname;
  const nom = storedUserInfo.lastname;
  const email = storedUserInfo.email;
  const telephone = storedUserInfo.phoneNumber;

  const logout = () => {
    if (window.confirm(t("disconnect"))) {
      localStorage.removeItem("user-info");
      history({
        pathname: "/login",
      });
    }
  };

  function handleMod(e: any, action:string) {
    e.preventDefault();
    action =="cancel" ? setMod(true) : setMod(false);
  }
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstname: prenom,
      lastname: nom,
      email: email,
      password: "",
      phoneNumber: telephone,
      confirmPassword: "",
    },
    onSubmit: async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        if (formik.values.password !== formik.values.confirmPassword) {
          alert("Confirmation du mot de passe erronée");
          setLoading(false);
          return;
        }

        const formData = new FormData();
        formData.append("lastname", formik.values.lastname);
        formData.append("firstname", formik.values.firstname);
        formData.append("email", formik.values.email);

        formData.append("phoneNumber", formik.values.phoneNumber);
        formData.append("password", formik.values.password);

        try {
          const response = await axios.post(
            `${apiUrl}/update-user/${id}`,
            formData
          );

          if (response.status === 200) {
            alert("Utilisateur modifié avec succès");
            setMod(true)
          } else {
            alert("Erreur lors de l'enregistrement de l'utilisateur");
            // toast.error('Erreur lors de l\'enregistrement de l\'utilisateur');
          }
        } catch (error) {
          alert("Erreur lors de l'enregistrement de l'utilisateur");
        }

        
      } catch (error) {
        console.error("Update failed", error);
      } finally {
        setLoading(true);
      }
    },
  });

  return (
    <>
      <DynamicNav />
      <div className="container mt-4">
        <div className="row justify-content-center mt-4">
          <form onSubmit={formik.handleSubmit} className="col-md-8 col-lg-7 p-4 rounded shadow-sm bg-light">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4>{t("profile.title")}</h4>
              <button className="btn btn-sm text-dark" onClick={logout}>
                <i className="fa-solid fa-right-from-bracket"></i>
              </button>
            </div>
            <div className="row g-3 ">
              <div className="col-md-6">
                <label className="form-label">{t("profile.name")} *</label>
                <input
                onChange={formik.handleChange}
                  type="text"
                  name="firstname"
                  className="form-control"
                  value={formik.values.firstname}
                  disabled={mod}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">{t("profile.lastName")} *</label>
                <input
                onChange={formik.handleChange}
                  type="text"
                  name="lastname"
                  className="form-control"
                  value={formik.values.lastname}
                  disabled={mod}
                />
              </div>
            </div>
            <div className="row g-3 mt-4">
              <div className="col-md-6">
                <label className="form-label">{t("profile.phone")} *</label>
                <input
                onChange={formik.handleChange}
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  value={formik.values.phoneNumber}
                  disabled={mod}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">{t("profile.email")} *</label>
                <input
                onChange={formik.handleChange}
                  type="email"
                  name="email"
                  className="form-control"
                  value={formik.values.email}
                  disabled={mod}
                />
              </div>
            </div>
            <div className="row g-3 mt-4" hidden={mod}>
              <div data-mdb-input-init className="form-outline mb-2 col-6">
                <input
                
                  required
                  type="password"
                  name="password" 
                  value={formik.values.password}
                  id="form3Example1"
                  placeholder="●●●●●●●●●●"
                  onChange={formik.handleChange}
                  className="form-control form-control-lg"
                />
                <label className="form-label" htmlFor="form3Example1">
                  {t("register.password")}
                </label>
              </div>
              <div data-mdb-input-init className="form-outline mb-2 col-6">
                <input
                  required
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  placeholder="●●●●●●●●●●"
                  id="form3Example2"
                  onChange={formik.handleChange}
                  className="form-control form-control-lg"
                />
                <label className="form-label" htmlFor="form3Example2">
                  {t("register.confirmPassword")}
                </label>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4">
              {mod ? (
                <>
                  <button
                    className="btn text-white"
                    style={{ backgroundColor: "#ffa64d" }}
                    onClick={(e)=> handleMod(e, "mod")}
                  >
                    <i className="fa-solid fa-pen mr-1"></i>
                    {t("profile.mod")}
                  </button>
                </>
              ) : (
                <>
                <button
                    type="submit"
                    className="btn bg-secondary text-white mr-2" 
                    onClick={(e)=>handleMod(e,"cancel")}
                  >
                   <i className="fa-solid fa-xmark mr-1"></i>
                    {t("profile.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn text-white"
                    style={{ backgroundColor: "#ffa64d" }}
                  >
                    <i className=" fa-regular fa-floppy-disk mr-1"></i>
                    {t("profile.save")}
                  </button>

                </>
              )}
            </div>
          </form>

          <div className="col-md-5 mt-4 mt-md-0">
            <img
              className="img-fluid rounded shadow"
              style={{ objectFit: "cover", maxHeight: "400px" }}
              src="https://images.unsplash.com/photo-1487611459768-bd414656ea10?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHdlYnNpdGUlMjBzaG9wcGluZ3xlbnwwfHwwfHx8MA%3D%3D"
              alt="Profile Background"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
