import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, initMDB } from "mdb-ui-kit";
import './script.js';
import { useTranslation } from "react-i18next";




const Home: React.FC = () => {

  useEffect(() => {
    initMDB({ Carousel });
  }, []);
  const { t } = useTranslation();
  
  return (
    <>
     
      <div id="carouselExampleControls" className="carousel slide carousel-fade " data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active text-center">
            <img className="d-inline-block img-fluid custom-img" src="bio.jpg" alt="Premier diapositive" />
            <div className="carousel-caption rounded-lg" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'black' }}>
            <h5> {t('Home.caroussel.0.title')}</h5>
            <p>{t('Home.caroussel.0.description')}</p>
                        </div>
          </div>
          <div className="carousel-item text-center">
            <img className="d-inline-block img-fluid custom-img" src="https://www.sicobel.com/app/uploads/2021/10/sicobel-magasin-bio2.jpg" alt="Pure Élégance" />
            <div className="carousel-caption" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'black' }}>
            <h5> {t('Home.caroussel.1.title')}</h5>
            <p>{t('Home.caroussel.1.description')}</p>
                        </div>
          </div>
          <div className="carousel-item text-center">
            <img className="d-inline-block img-fluid custom-img" src="https://www.lavieclaire.com/wp-content/uploads/2024/02/LVC-HEADER-2021_mai_S3-1920x600.jpg" alt="Le Meilleur de la Nature" />
            <div className="carousel-caption" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'black' }}>
            <h5> {t('Home.caroussel.2.title')}</h5>
            <p>{t('Home.caroussel.2.description')}</p>
                        </div>
          </div>
          <div className="carousel-item text-center">
            <img className="d-inline-block img-fluid custom-img" src="https://rampal-latour.com/cdn/shop/collections/savon-de-marseille-authentique-huile-olive-rampal-latour_078a135f-5c37-4705-bb71-7c50ac3213f0.jpg?v=1693222665" alt="Glamour Vert" />
            <div className="carousel-caption" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'black' }}>
            <h5> {t('Home.caroussel.3.title')}</h5>
            <p>{t('Home.caroussel.3.description')}</p>
            
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Précédent</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Suivant</span>
        </a>
      </div>

      <div className="text-center mt-5 mb-4 pt-5">
        <h1 style={{ fontFamily: 'Brush Script MT' }}>Les Douceurs du Maroc</h1>
      </div>

      <div className="container mb-3 mt-5">
        <div className="row mb-4 w3-animate-zoom">
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <img src="favicon.ico" className="img-fluid" alt="Icône" />
          </div>
          <div className="col-md-8 d-flex flex-column justify-content-center text-center text-md-left">
            <h4>{t('Home.cards.0.title')}</h4>
            <p className="mb-0">
            {t('Home.cards.0.description')}
            </p>
          </div>
        </div>
        <hr />
        <div className="row mb-4 w3-animate-zoom">
        <div className="col-md-8 d-flex flex-column justify-content-center text-center text-md-left">
            <h4>{t('Home.cards.1.title')}</h4>
            <p className="mb-0">
            {t('Home.cards.1.description')}
            </p>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img src="https://www.pngmart.com/files/21/100-Natural-PNG-Photo.png" className="img-fluid" alt="Ingrédients Organiques" />
          </div>
        </div>
        <hr />
        <div className="row w3-animate-zoom">
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <img src="https://images.squarespace-cdn.com/content/v1/5b69b60b2714e5c8dbc5c0a7/c038ebe2-8c10-48a6-9cd5-7922cdec3d4d/Heritage-logo-final-print.png" className="img-fluid" alt="Héritage Marocain" />
          </div>
          <div className="col-md-8 d-flex flex-column justify-content-center text-center text-md-left">
            <h4>{t('Home.cards.2.title')}</h4>
            <p className="mb-0">
            {t('Home.cards.2.description')}
            </p>
          </div>
        </div>
      </div>
   
      <Footer />
    </>
  );
}

export default Home;
