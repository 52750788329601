import React, { useState, ChangeEvent, useEffect, useContext } from "react";
import Footer from "./Footer";
import { RotatingLines } from "react-loader-spinner";
import "../flyCss.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../App.css";
import "./carroussel.css";
import { currencyFormat } from "./CurrencyFormat";
import { t } from "i18next";
import { GlobalContext } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { apiUrl } from "../process";

interface ProdsProps {
  categoriesList: any[];
  sub: any[];
  prods: any[];
}

const Prods: React.FC<ProdsProps> = ({ prods, categoriesList, sub }) => {
  const [showAside, setShowAside] = useState(false);

  const [subCat, setSubcat] = useState("");

  const [quantity, setQuantity] = useState(1);

  const storedUserInfo = JSON.parse(localStorage.getItem("user-info") || "{}");
  const id = storedUserInfo.id || "";

  const [minValue, setMinValue] = useState<number>(10);
  const [maxValue, setMaxValue] = useState<number>(3000);

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (value <= maxValue) {
      setMinValue(value);
    }
  };
  const handleMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (value >= minValue) {
      setMaxValue(value);
    }
  };

  const [filProd, setFil] = useState(prods);
  useEffect(() => {
    if (prods) {
      setFil(prods);
    }
  }, [prods]);

  const filteredProds = () => {
    setFil(
      prods.filter(
        (prod: { price: number }) =>
          prod.price >= minValue && prod.price <= maxValue
      )
    );
  };
  const { cat } = useParams<{ cat: string }>();

  const [search, setSearch] = useState("");

  const [searchCat, setSearchCat] = useState("");

  const filterProducts = () => {
    let filteredProds = prods;

    if (cat) {
      filteredProds = filteredProds.filter(
        (prod: { category: string }) => prod.category === cat
      );
    }

    if (search) {
      filteredProds = filteredProds.filter((prod: { title: string }) =>
        prod.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFil(filteredProds);
  };

  useEffect(() => {
    filterProducts();
  }, [search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
    filterProducts();
  };
  const handleSearchCat = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchCat(e.target.value);
  };

  const listDisplay = () => {
    const gridElement = document.getElementById("grid");
    const listElement = document.getElementById("list");

    if (gridElement) {
      gridElement.hidden = true;
    }
    if (listElement) {
      listElement.style.display = "block";
    }
  };

  const gridDisplay = () => {
    const gridElement = document.getElementById("grid");
    const listElement = document.getElementById("list");

    if (listElement) {
      listElement.style.display = "none";
    }
    if (gridElement) {
      gridElement.hidden = false;
    }
  };

  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  function on(
    e: any,
    prod: {
      picture: string;
      title: string;
      price: number;
      id: number;
      category: string;
      productImages: [
        {
          id: number;
          picture: string;
        },
      ];
      variantProducts: [
        { 
          id: number; 
          variant: 
          { id: number; label: string }
         }];
    }
  ) {
    e.preventDefault();
    
    const overlay = document.querySelector(".overlay") as HTMLElement;
    let quantity = 1;
    if (overlay) {
      const overlayContent = overlay.querySelector(
        ".overlay-content"
      ) as HTMLElement;

      if (overlayContent) {
        overlayContent.innerHTML = `
        <div class="row " style="max-height: 55vh; overflow-y: auto;">
          <!-- Sidebar with small images -->
          <div class="col-md-1 col-sm-12 d-flex  align-items-center" style="overflow-y: auto; padding-left: 0;">
            <ul class="list-unstyled flex-md-column flex-row d-flex align-items-center">
              ${prod.productImages
                .map(
                  (filteredProd: any) => `
                    <li class="nav-link prodsImg"  >
                      <img 
                        src="https://api.faragroupe.fr/uploads/product_images/${filteredProd.picture}"
                        
                        style="height: 60px; width: 60px;  object-fit: contain;"
                        alt="Product ${filteredProd.id}"
                      />
                    </li>
                  `
                )
                .join("")}
            </ul>
          </div>
          <!-- Main product image -->
          <div class="col-md-4 col-sm-12 mb-3 justify-content-center align-items-center">
            <img 
              src="https://api.faragroupe.fr/uploads/product_images/${prod.picture}" 
              alt="${prod.title}" 
              class="img-fluid overlayimg" 
              style="width: 100%; max-height: 50vh; object-fit: contain;" 
            />
          </div>
         
          <div class="col-md-6 col-sm-12 " style="max-height: 80vh; overflow-y: auto;">
            <h3 class="text-dark text-center text-md-left">${prod.title}</h3>
            
            <p class="text-center text-md-left" style="color: #FD5C1E;"><strong>${prod.price}.00 DH </strong></p>
      
            <div class="d-flex align-items-md-between">
              <div class="col-md-4 col-6">
              
                <select class="form-select" style="height: 35px">
                
                <option>100g</option>
                <option>200g</option>
                <option>1kg</option>
                </select>
             </div>
              <div class="d-flex flex-row justify-content-center align-items-center mb-3">
                <button class="btn btn-outline-light text-dark px-2 minus-btn">
                  <i class="fas fa-minus"></i>
                </button>
      
                <input
                  name="quantity"
                  value=${quantity}
                  type="text"
                  class="form-control form-control-sm quantity-input text-center mx-2"
                  style="width: 55px;"
                />
      
                <button class="btn btn-outline-light text-dark px-2 plus-btn">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
 </div>
              <div class="d-flex mt-4">
                   <button
      data-mdb-button-init
      data-mdb-ripple-init
      class="btn btn-md mb-2 addBtn"
      type="button"
      style="background-color: #F1C569 "
                  >
                    ${
                      loading ? (
                        <RotatingLines
                          width="24"
                          strokeColor="white"
                          strokeWidth="5"
                          animationDuration="0.75"
                        />
                      ) : (
                        t("wishlist.add")
                      )
                    }
                  </button>
             
              
              <a class="heart ml-2 mb-3" href="/like">
                <i class="fas fa-heart"></i>
              </a>
              </div>
           
            <a href="/details/${prod.id}"> <i class="fa-solid fa-arrow-right"></i> ${t("fullDet")}</a>
          </div>
        </div>
      `;

        const minusBtn = overlayContent.querySelector(
          ".minus-btn"
        ) as HTMLElement;
        const plusBtn = overlayContent.querySelector(
          ".plus-btn"
        ) as HTMLElement;
        const inputElement = overlayContent.querySelector(
          ".quantity-input"
        ) as HTMLInputElement;
        const addBtn = overlayContent.querySelector(".addBtn") as HTMLElement;

        minusBtn?.addEventListener("click", () => {
          let currentQuantity = parseInt(inputElement.value);

          if (currentQuantity > 1) {
            currentQuantity -= 1;
            setQuantity(quantity - 1);
            inputElement.value = currentQuantity.toString();
          }
        });

        plusBtn?.addEventListener("click", () => {
          let currentQuantity = parseInt(inputElement.value); // Get the current input value
          currentQuantity += 1; // Increment the current quantity

          // Update the actual input field value
          inputElement.value = currentQuantity.toString();

          // Update the quantity state with the new value
          setQuantity(currentQuantity);

          // Check the new quantity in the console
          console.log(currentQuantity);
        });

        addBtn?.addEventListener("click", (e) => {
          let currentQuantity = parseInt(inputElement.value);
          addToCart(e, prod.id, currentQuantity, "btn");
        });

        overlay.style.display = "block";
      }
    }
  }
  function animateToCart(e: any) {
    const cartIcon = document.querySelector(".cart-container");
    const cartIconPosition = cartIcon?.getBoundingClientRect();

    const buttonPosition = e?.getBoundingClientRect();

    const flyElement = document.createElement("div");
    flyElement.classList.add("fly-animation");

    flyElement.style.position = "absolute";
    if (buttonPosition) {
      flyElement.style.left = buttonPosition.left + window.scrollX + "px";
      flyElement.style.top = buttonPosition.top + window.scrollY + "px";
    }

    document.body.appendChild(flyElement);

    setTimeout(() => {
      flyElement.style.transition = "all 1s ease"; // Add a smooth transition
      if (cartIconPosition) {
        flyElement.style.left = cartIconPosition.left + window.scrollX + "px"; // Move to cart's left position
        flyElement.style.top = cartIconPosition.top + window.scrollY + "px"; // Move to cart's top position
        flyElement.style.transform = "scale(0.3)"; // Optional: Shrink the flying element during the transition
      }

      // Remove the flying element after the animation ends
      const removeElement = () => {
        document.body.removeChild(flyElement);
        flyElement.removeEventListener("transitionend", removeElement); // Clean up the event listener
      };

      flyElement.addEventListener("transitionend", removeElement);
    }, 100);
  }

  const { cart, setCart } = useContext(GlobalContext);
  useEffect(() => {
    const savedCart = localStorage.getItem("user-cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, [setCart]);

  useEffect(() => {
    localStorage.setItem("user-cart", JSON.stringify(cart));
  }, [cart]);

  const history = useNavigate();
  const addToCart = (e: any, id: number, quantity: number, action: string) => {
    setLoading(true);

    setCart((prevCart: any) => {
      if (!prevCart) {
        prevCart = [];
      }

      const res = prevCart.findIndex((i: { id: number }) => i.id === id);

      if (res === -1) {
        // If the product is not in the cart, add it
        const prod = { id, quantity };
        return [...prevCart, prod];
      } else {
        // If the product is already in the cart, update its quantity
        const updatedCart = prevCart.map((item: any) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity + quantity }; // Create a new object with updated quantity
          }
          return item;
        });

        return updatedCart;
      }
    });

    if (action == "btn") {
      setTimeout(() => {
        if (
          window.confirm(
            "Produit ajouté avec success !\nValidez votre commande ou continuez votre achat ?"
          )
        ) {
          history({
            pathname: "/cart",
          });
        } else {
          off(e);
          setLoading(false);
        }
      }, 500);
    } else {
      animateToCart(e.target);
    }
  };

  const X = document.getElementById("closeButton");
  if (X) {
    X.addEventListener("click", off);
  }

  function off(e: any) {
    e.preventDefault();
    const overlay = document.querySelector(".overlay") as HTMLElement;
    if (overlay) {
      overlay.style.display = "none";
    }
  }

  function chunkArray(array: any[], size: number) {
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }
    return chunked;
  }

  const addFav = async (e: React.MouseEvent, idProduct: number) => {
    e.preventDefault();

    const heartIcon = e.target as HTMLElement;

    if (heartIcon.classList.contains("favorite")) {
      heartIcon.classList.remove("favorite");
      heartIcon.style.removeProperty("color");
      toast(t("toast.remove"));
    } else {
      const favData = new FormData();
      favData.append("product_id", idProduct.toString());
      favData.append("user_id", id.toString());

      try {
        const response = await axios.post(
          "https://api.faragroupe.fr/api/favorites",
          favData
        );

        if (response.status === 200) {
          heartIcon.classList.add("favorite");
          heartIcon.style.color = "red";
          toast(t("toast.add"));
        } else {
          alert("Erreur lors de lajout");
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          console.error("Axios error:", error.response?.data || error.message);
        }
      }
    }
  };

  return (
    <>
      <button
        className="btn ml-2 btn-light d-md-none text-dark"
        onClick={() => setShowAside(!showAside)}
      >
        {showAside ? (
          <i className="fa-solid fa-arrow-left"></i>
        ) : (
          <i className="fa-solid fa-arrow-right"></i>
        )}
      </button>
      <section className="products-page">
        <aside
          className={`aside-section ${showAside ? "" : "d-none d-md-block"}`}
        >
          <div>
            <h5 style={{ color: "#9A7647" }}>
              <Link to="/produits">
                <i className="fa-solid fa-filter"></i> Categories
              </Link>
            </h5>
            <ul className="categories-list">
              <div className="search-bar">
                <input
                  onChange={handleSearchCat}
                  value={searchCat}
                  type="text"
                  className="rounded-pill"
                  placeholder={t("search")}
                />
                <button disabled type="button" className="search-button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
              <li
                className="btn btn-sm btn-white"
                style={{ display: searchCat || subCat ? "block" : "none" }}
                onClick={() => {
                  setSubcat("");
                  setSearchCat("");
                }}
              >
                <i className="fa-solid fa-arrow-rotate-left"></i>
              </li>
              {sub
                .filter((prod) =>
                  prod.title.toLowerCase().includes(searchCat.toLowerCase())
                )
                .map((cat) => (
                  <li
                    key={cat.id}
                    onClick={() => {
                      setSubcat(cat.title);
                    }}
                  >
                    <div className="dropdown-header">
                      <a className="dropdown-item">
                        <img
                          className="mr-2 rounded-circle"
                          style={{ height: "14px", width: "12px" }}
                          src={`https://api.faragroupe.fr/uploads/category_images/${cat.picture}`}
                        />
                        {cat.title}
                      </a>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <hr />
          <div className="price-filter">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 style={{ color: "#9A7647" }}>
                <i className="fa-solid fa-sort"></i> {t("price")} (DH)
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-light text-dark"
                onClick={filteredProds}
              >
                OK
              </button>
            </div>

            <div className="range-slider">
              <input
                type="range"
                min="10"
                max="3000"
                value={minValue}
                onChange={handleMinChange}
                className="min-range"
              />
              <input
                type="range"
                min="10"
                max="3000"
                value={maxValue}
                onChange={handleMaxChange}
                className="max-range"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <input
                  type="number"
                  name="min"
                  className="pi text-center"
                  value={minValue}
                  placeholder="Min"
                  max="3000"
                  min="10"
                  onChange={handleMinChange}
                />
              </div>
              <span className="mx-2">-</span>
              <div>
                <input
                  type="number"
                  className="pi text-center"
                  name="max"
                  value={maxValue}
                  placeholder="Max"
                  min="10"
                  max="3000"
                  onChange={handleMaxChange}
                />
              </div>
            </div>
          </div>
        </aside>
        <div className="main-content">
          <section className="text-center">
            <div
              className="carousel slide carousel-fade stylish-carousel"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                {chunkArray(categoriesList, 1).map((_, index) => (
                  <li
                    key={index}
                    data-target="#carouselExampleIndicators"
                    data-slide-to={index}
                    className={index === 0 ? "active" : ""}
                  ></li>
                ))}
              </ol>

              {/* Carousel Inner */}
              <div className="carousel-inner">
                {chunkArray(categoriesList, 1).map((chunk, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    {/* Full-width Category Images */}
                    <div className="full-width-carousel d-flex justify-content-center align-items-center">
                      {chunk.map((cat) => (
                        <>
                          <div
                            style={
                              subCat !== ""
                                ? { display: "none" }
                                : { display: "block" }
                            }
                            key={cat.id}
                            className="position-relative image-container"
                          >
                            <img
                              src={`https://api.faragroupe.fr/uploads/category_images/${cat.picture}`}
                              alt={cat.title}
                              className="carousel-img"
                            />

                            {/* Gradient Overlay */}
                            <div className="gradient-overlay"></div>

                            {/* Category Title with Animation */}
                            <div className="category-title">
                              <h2>{cat.title}</h2>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              {/* Carousel Controls */}
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </section>
          <div
            style={
              subCat !== ""
                ? { display: "block", height: "150px" }
                : { display: "none" }
            }
            className="position-relative image-container"
          >
            {categoriesList
              .filter((category) => category.title.includes(subCat))
              .map((category) => (
                <div
                  key={category.id}
                  className="img-container rounded"
                  style={{
                    backgroundImage: `url(https://api.faragroupe.fr/uploads/category_images/${category.picture})`,
                  }}
                >
                  <div className="gra-overlay"></div>
                  <h2 className="cat-title">{subCat || category.title}</h2>

                  {/* Category Title */}
                </div>
              ))}
          </div>

          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex">
              <button
                className="btn btn-lg btn-light text-dark mr-1"
                style={{ zIndex: 10 }}
                onClick={listDisplay}
              >
                <i className="fa-solid fa-list"></i>
              </button>
              <button
                style={{ zIndex: 10 }}
                className="btn btn-lg btn-light text-dark"
                onClick={gridDisplay}
              >
                <i className="fa-solid fa-border-all"></i>{" "}
              </button>
            </div>
            <div className="search-bar">
              <input
                type="text"
                onChange={handleSearchChange}
                className="rounded-pill"
                value={search}
                placeholder={t("search")}
              />
              <button disabled type="button" className="search-button">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>

          {filProd.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center">
              {" "}
              <span className="mr-3">{t("noProds")}</span>{" "}
              <i className="fa fa-face-frown-open"></i>
            </div>
          ) : null}

          <section className="section-products">
            <div className="container">
              <div className="row" id="grid">
                {filProd
                  .filter(
                    (prods: any) =>
                      subCat === "" ||
                      prods.categorie[1].title.toLowerCase() ===
                        subCat.toLowerCase()
                  )
                  .map((prod: any) => (
                    <div
                      className="col-xs-7 col-sm-5 col-md-6 col-lg-5 col-xl-3 products-list "
                      key={prod.id}
                    >
                      <div
                        id="product-1"
                        className="single-product align-items-center"
                      >
                        <div className="part-1">
                          <Link to={`/details/${prod.id}`}>
                            <img
                              src={`https://api.faragroupe.fr/uploads/product_images/${prod.picture}`}
                              alt={prod.title}
                              className="product-image rounded"
                            />
                          </Link>
                          <ul>
                            <li>
                              <a
                                className="addCart"
                                onClick={(e: any) =>
                                  addToCart(e, prod.id, 1, "a")
                                }
                              >
                                <i className="fas fa-shopping-cart"></i>
                              </a>
                            </li>

                            <li onClick={(e) => addFav(e, prod.id)}>
                              <a>
                                <i className="fas fa-heart"></i>
                              </a>
                            </li>
                            <li onClick={(e) => on(e, prod)}>
                              <a>
                                <i className="fas fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <Link to={`/details/${prod.id}`}>
                                <i className="fa-solid fa-circle-info"></i>
                              </Link>
                            </li>
                          </ul>
                          <ToastContainer position="bottom-right" />
                        </div>

                        <div className="part-2">
                          <span className="text-dark product-title">
                            {prod.title}
                          </span>
                          <br />
                          <div className="d-flex justify-content-end mt-2">
                            <h4
                              className="product-price "
                              style={{ color: "#fc6603" }}
                            >
                              <strong className="mr-2">
                                {currencyFormat(prod.price)}
                              </strong>
                              <small className="rounded text-muted ">
                                -28%
                              </small>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="row" id="list" style={{ display: "none" }}>
                {filProd
                  .filter(
                    (prods: any) =>
                      subCat === "" ||
                      prods.categorie[1].title.toLowerCase() ===
                        subCat.toLowerCase()
                  )
                  .map((prod: any) => (
                    <div
                      key={prod.id}
                      className="card-body mb-2 rounded-2"
                      style={{ backgroundColor: "#e1dede27" }}
                    >
                      <div className="row">
                        <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                          <Link to={`/details/${prod.id}`}>
                            <div className="bg-image hover-zoom ripple rounded ripple-surface">
                              <img
                                src={`https://api.faragroupe.fr/uploads/product_images/${prod.picture}`}
                                className="w-100"
                              />
                              <a href="#!">
                                <div className="hover-overlay">
                                  <div className="mask"></div>
                                </div>
                              </a>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <h5>{prod.title}</h5>
                          <div className="d-flex flex-row">
                            <div className="text-danger mb-1 me-2">
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </div>
                            <span>310</span>
                          </div>
                          <div className="mb-2 text-muted small">
                            <span>{prod.category}</span>
                          </div>
                          <p className="text-truncate mb-4 mb-md-0">
                            {prod.title}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                          <div className="d-flex flex-row align-items-center mb-1">
                            <h4 className="mb-1 me-1">
                              {currencyFormat(prod.price)}
                            </h4>
                          </div>
                          <h6 className="text-success ">{t("free")}</h6>
                          <button
                            className="btn btn-sm mt-2"
                            style={{
                              backgroundColor: "#F1C569",
                              outline: "none",
                              boxShadow: "none",
                            }}
                            onClick={(e: any) => addToCart(e, prod.id, 1, "")}
                          >
                            {t("add")}
                          </button>
                          <div className="d-flex">
                            <button
                              data-mdb-button-init
                              data-mdb-ripple-init
                              className="btn  text-dark btn-sm mt-2"
                              type="button"
                            >
                              <a className="heart" href="/like">
                                <i className="fas fa-heart"></i>
                              </a>
                            </button>
                            <button
                              onClick={(e) => on(e, prod)}
                              data-mdb-button-init
                              data-mdb-ripple-init
                              className="btn  text-dark btn-sm mt-2"
                              type="button"
                            >
                              <a className="eye">
                                <i className="fas fa-eye"></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>

          <div className="overlay slide-top ">
            <div className="modal-contents">
              <button className="close" onClick={off}>
                +
              </button>
              <div className="overlay-content p-4 "></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Prods;
