import React, { useState, ChangeEvent, useEffect, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
// import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import "./detCss.css";
import "../Responsive.css";
import { t } from "i18next";
import DynamicNav from "./dynamicNav";
import { GlobalContext } from "../App";

const Details: React.FC = () => {
  const prods = useContext(GlobalContext).pr;

  interface Variant {
    id: number; // ID of the main variant
    disponibilite: string; // Availability status
    price: number; // Price of the variant
    stock: number; // Stock quantity
    variant: {
      id: number;
      label: string;
    };
  }
  interface Product {
    id: number;
    picture: string;
    title: string;
    productImages: [
      {
        id: number;
        picture: string;
      },
    ];
    description: string;
    category: string;
    price: number;
    variants: Variant[];
  }

  const { id } = useParams();
  // const cart = useContext(GlobalContext).cart;
  const setCart = useContext(GlobalContext).setCart;

  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState<Product>();
  const [vars, setVar] = useState<Variant[]>();

  const [content, setContent] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const foundProduct = prods.find(
      (prod: { id: number }) => prod.id.toString() === id
    );
    if (foundProduct) {
      
      setVar(foundProduct.variantProducts);
      setProduct(foundProduct);
      setContent(
        `https://api.faragroupe.fr/uploads/product_images/${foundProduct.picture}`
      );
    }
    setLoading(false);
  }, [id, prods]);

  // Conditional rendering based on loading state
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status"></div>
      </div>
    );
  }

  const addToCart = (e: any, id: number, quantity: number) => {
    setLoading(true);

    setCart((prevCart: any) => {
      if (!prevCart) {
        prevCart = [];
      }

      const res = prevCart.findIndex((i: { id: number }) => i.id === id);

      if (res === -1) {
        // If the product is not in the cart, add it
        const prod = { id, quantity };
        return [...prevCart, prod];
      } else {
        // If the product is already in the cart, update its quantity
        const updatedCart = prevCart.map((item: any) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity + quantity }; // Create a new object with updated quantity
          }
          return item;
        });

        return updatedCart;
      }
    });
    alert("Ajouté avec success!");
    setLoading(false);
  };

  const handleMouseEnter = (src: string | undefined) => {
    setContent(`https://api.faragroupe.fr/uploads/product_images/${src}`);
  };

  function handleQuantityChange(id: number, action: string, price: number) {
    // Update local quantity state
    if (action === "stepUp") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else {
      setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    }

    // Update cart
    setCart((prevCart: any[]) => {
      // Check if the item already exists in the cart
      const itemExists = prevCart.find((item) => item.id === id);

      if (itemExists) {
        // Update quantity for existing item
        return prevCart.map((item) => {
          if (item.id === id) {
            const newQuantity =
              action === "stepUp"
                ? item.quantity + 1
                : Math.max(item.quantity - 1, 1);
            return { ...item, quantity: newQuantity };
          }
          return item; // Return other items unchanged
        });
      } else {
        // Add new item to cart if not found
        return [...prevCart, { id, quantity: 1, price }];
      }
    });
  }

  return (
    <>
      {/* <Header categoriesList={[]} sub={[]}  /> */}
      {/* <nav className="d-flex ml-4">
        <Link to="/">
          <i className=" mr-1 fa-solid fa-house"></i>
        </Link>
        <span className="mr-2">/</span>
        <Link to="/produits">Produits</Link>
        <span className="mr-1 ml-1">/</span>
        <Link to={`/details/${product?.id}`}>{product?.name}</Link>
      </nav> */}

      <DynamicNav name={product?.title} />
      <section className="py-5">
        <div className="container">
          <div className="row gx-5">
            <aside className="col-lg-6">
              <div className="rounded-3 mb-3 d-flex justify-content-center">
                <a
                  data-fslightbox="mygalley"
                  className="rounded-4 justify-content-center"
                  data-type="image"
                  href={content}
                >
                  <img className="responsive-box rounded-3 fit" src={content} />
                </a>
              </div>

              <ul className="d-flex pl-2 justify-content-center mb-3">
                <li className="nav-link prodsImg">
                  <img
                    src={`https://api.faragroupe.fr/uploads/product_images/${product?.picture}`}
                    onMouseEnter={() => handleMouseEnter(product?.picture)}
                    style={{ height: "95px", width: "95px" }}
                  />
                </li>
                {product?.productImages.map((filteredProd) => (
                  <li key={filteredProd.id} className="nav-link prodsImg">
                    <img
                      src={`https://api.faragroupe.fr/uploads/product_images/${filteredProd.picture}`}
                      onMouseEnter={() =>
                        handleMouseEnter(filteredProd.picture)
                      }
                      style={{ height: "95px", width: "95px" }}
                    />
                  </li>
                ))}
              </ul>
            </aside>
            <main className="col-lg-6">
              <div className="ps-lg-3">
                <h4 className="title text-dark">
                  {product && <>{product.title}</>}
                </h4>

                <div className="d-flex flex-row my-3">
                  <div className="text-warning mb-1 me-2">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fas fa-star-half-alt"></i>
                    <span className="ms-1">4.5</span>
                  </div>
                  <span className="ml-2 text-success ms-2">
                    {t("details.stock")}
                  </span>
                </div>

                <div className="mb-3">
                  <span className="h5">
                    {" "}
                    {product && <>{product.price}</>}.00 DH
                  </span>
                </div>

                <p>{product && <>{product.description}</>}</p>
                <hr />

                <div className="row mb-4">
                  <div className="col-md-4 col-6">
                    <label className="mb-2">{t("details.capacity")} : </label>
                    <br />
                    <select
                      className="form-select border border-secondary"
                      style={{ height: "35px" }}
                    >
                      {vars?.map((variant: Variant) => (
                        <option key={variant.variant.id}>
                          {variant.variant.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 col-6 mb-3">
                    <label className="mb-2 d-block">
                      {t("details.quantity")}
                    </label>
                    <div
                      className="input-group mb-3"
                      style={{ width: "170px" }}
                    >
                      <button
                        className="btn btn-outline-light text-dark px-2"
                        onClick={() =>
                          handleQuantityChange(
                            product?.id ?? 0,
                            "stepDown",
                            product?.price ?? 0
                          )
                        }
                      >
                        <i className="fas fa-minus"></i>
                      </button>

                      <input
                        name="quantity"
                        value={quantity}
                        type="text"
                        className="form-control form-control-sm text-center"
                        style={{ boxShadow: "none" }}
                        readOnly
                      />

                      <button
                        className="btn btn-outline-light text-dark px-2"
                        onClick={() =>
                          handleQuantityChange(
                            product?.id ?? 0,
                            "stepUp",
                            product?.price ?? 0
                          )
                        }
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <a href="#" className="btn btn-warning shadow-0 mr-1">
                  {" "}
                  {t("details.commande")}{" "}
                </a>
                <a
                  className="btn btn-dark shadow-0"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    addToCart(e, product?.id ?? 0, 1)
                  }
                >
                  {" "}
                  <i className="me-1 fa fa-shopping-basket"></i>{" "}
                  {t("details.add")}{" "}
                </a>
              </div>
            </main>
          </div>
        </div>
      </section>

      <section className="bg-light border-top py-4">
        <div className="container">
          <h5 className="card-title">{t("details.articles")}</h5>

          <div className="px-0 border rounded-2 shadow-0">
            <div className="card">
              <div className="card-body row">
                {prods.filter(
                  (prod: { category: any; id: any }) =>
                    prod.category === product?.category &&
                    prod.id != product?.id
                ).length > 0 ? (
                  prods
                    .filter(
                      (prod: { category: any; id: any }) =>
                        prod.category === product?.category &&
                        prod.id != product?.id
                    )
                    .map(
                      (filteredProd: {
                        id: number;
                        picture: string;
                        title: string;
                        price: number;
                      }) => (
                        <div
                          className="d-flex mb-3 col-12 col-sm-6 col-md-4 col-lg-3"
                          key={filteredProd.id}
                        >
                          <a
                            href={`/details/${filteredProd.id}`}
                            className="me-3"
                          >
                            <img
                              src={`https://api.faragroupe.fr/uploads/product_images/${filteredProd.picture}`}
                              style={{ minWidth: "96px", height: "96px" }}
                              className="img-md"
                            />
                          </a>
                          <div className="nav-link mb-1">
                            <a href={`/details/${filteredProd.id}`}>
                              {filteredProd.title}
                            </a>
                            <br />
                            <strong className="text-dark">
                              {" "}
                              {filteredProd.price}.00 DH
                            </strong>
                          </div>
                        </div>
                      )
                    )
                ) : (
                  <span>{t("details.noArticle")}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Details;
