import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DynamicNav from "./dynamicNav";
import Footer from "./Footer";
import { GlobalContext } from "../App";
import axios from "axios";
import { use } from "i18next";
import { currencyFormat } from "./CurrencyFormat";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { doc } from "prettier";
import { useFormik } from "formik";
import { apiImg } from "../process";

function ShoppingCart() {
  const navigate = useNavigate();
  const prods = useContext(GlobalContext).pr;

  const { cart, setCart } = useContext(GlobalContext);
  useEffect(() => {
    const savedCart = localStorage.getItem("user-cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, [setCart]);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("user-cart", JSON.stringify(cart));
  }, [cart]);
  const { t } = useTranslation();
  // const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);

  function handleQuantityChange(id: number, action: string, price: number) {
    setCart((prevCart: any[]) => {
      return prevCart.reduce(
        (
          newCart: any,
          item: { id: number; quantity: number; price: number }
        ) => {
          if (item.id === id) {
            if (action === "stepUp") {
              setTotal(price * (item.quantity + 1));
              return [...newCart, { ...item, quantity: item.quantity + 1 }];
            } else if (action === "stepDown") {
              if (item.quantity > 1) {
                setTotal(price * (item.quantity - 1));
                return [...newCart, { ...item, quantity: item.quantity - 1 }];
              } else {
                const confirmRemove = window.confirm(t("Cart.alert"));
                if (confirmRemove) {
                  return newCart;
                }
              }
            }
          }
          setTotal(item.price * item.quantity);
          return [...newCart, item];
        },
        []
      );
    });
  }

  useEffect(() => {
    const newTotal = cart.reduce(
      (acc: number, cartItem: { id: any; quantity: number }) => {
        const product = prods.find((p: { id: any }) => p.id === cartItem.id);
        const price = product ? product.price : 0;
        return acc + price * cartItem.quantity;
      },
      0
    );

    setTotal(newTotal);
  }, [cart]);

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (value: string) => {
    const elements = {
      credit: document.getElementById("credit"),
      debit: document.getElementById("debit"),
      paypal: document.getElementById("paypal"),
    };

    setSelectedOption(value);

    Object.entries(elements).forEach(([key, element]) => {
      if (element) {
        element.style.display = key === value ? "block" : "none";
      }
    });
  };

  const storedUserInfo = localStorage.getItem("user-info");
  const user = storedUserInfo ? JSON.parse(storedUserInfo) : null;

  // const formik = useFormik({
  //   initialValues: {
  //     nom : user.lastname,
  //     prenom : user.firstname,
  //     email : user.email,
  //     telephone: user.phoneNumber,
  //     pays: "",
  //     region: "",
  //     codepostal: "",
  //     rueInfo: ""
  //   },
  //   onSubmit: async () => {
  //     // setLoading(true);
  //     try {
  //       await new Promise((resolve) => setTimeout(resolve, 500));

  //       const formData = new FormData();
  //       formData.append("password", formik.values.nom);
  //       formData.append("prenom", formik.values.prenom);
  //       formData.append("telephone", formik.values.telephone);
  //       formData.append("pays", formik.values.pays);
  //       formData.append("region", formik.values.region);
  //       formData.append("codepostal", formik.values.codepostal);
  //       formData.append("rueInfo", formik.values.rueInfo);
  //       formData.append("paymentMethod", selectedOption);
  //       formData.append("totalPrice", total);
  //       formData.append("email", formik.values.email);

  //       const logindata = {
  //         password: formik.values.password,
  //         email: formik.values.email,
  //       };

  //       const loginResponse = await axios.post(
  //         "https://api.faragroupe.fr/api/login",
  //         logindata
  //       );

  //       const data = loginResponse.data;
  //       const token = data.token;

  //       if (data.ERROR) {
  //         alert("Connexion erronée")
  //         console.log(data.ERROR);
  //       } else {
  //         const userInfoResponse = await axios.get(
  //           "https://api.faragroupe.fr/api/me",
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );

  //         localStorage.setItem(
  //           "user-info",
  //           JSON.stringify(userInfoResponse.data)
  //         );

  //         navigate({
  //           pathname: `/profile/${userInfoResponse.data.id}`,
  //         });
  //       }
  //     } catch (error) {
  //       alert("Connexion erronée")
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  // });

  const [nom, setNom] = useState(user ? user.lastname : "");
  const [prenom, setPrenom] = useState(user ? user.firstname : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [telephone, setTelephone] = useState(user ? user.phoneNumber : "");
  const [pays, setPays] = useState("");
  const [region, setRegion] = useState("");
  const [codepostal, setCodePostal] = useState("");
  const [rueInfo, setRueInfo] = useState("");

  const handleNomChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNom(e.target.value);
  const handlePrenomChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPrenom(e.target.value);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const handleTelephoneChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTelephone(e.target.value);
  const handlePaysChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setPays(e.target.value);
  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setRegion(e.target.value);
  const handleCodePostalChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCodePostal(e.target.value);
  const handleRueInfoChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRueInfo(e.target.value);

  async function validateOrder() {
    if (user) {
      alert("Veuillez completer votre adresse de livraison");
      const shippingInfo = document.getElementById("shippingInfo");
      if (shippingInfo) {
        shippingInfo.style.display = "block";
      }
    } else {
      if (
        window.confirm("Vous devez vous connecter pour passer votre commande!")
      ) {
        navigate("/login");
      }
    }
  }
  async function checkOut() {
    if (cart.length === 0) {
      alert("Votre panier est vide!");
      return;
    }
    const orderDetails = {
      user: `/api/users/${user.id}`,
      totalPrice: total,
      orderStatue: "pending",
      paymentStatue: "pending",
      paymentMethod: "en ligne",
      orderItems: cart.map((item: { id: any; quantity: any }) => ({
        product: `/api/products/${item.id}`,
        quantity: item.quantity,
        orderNote: "hejggf",
      })),
      livraisonAdresse: {
        firstName: prenom,
        lastName: nom,
        email: email,
        telephone: telephone,
        pays: pays,
        region: region,
        codepostal: codepostal,
        rueInfo: rueInfo,
        adresseType: "string",
      },
    };

    const orderDetailsJson = JSON.stringify(orderDetails);
    const Orders = await axios.post(
      "https://api.faragroupe.fr/api/orders",
      orderDetailsJson,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setCart([]);
    alert("Commande validée avec succes!");
    navigate("/produits");

    const data = Orders.data;
  }

  return (
    <>
      <DynamicNav />

      <section className="h-100 ">
        <div className="container mt-3">
          <div className="row ">
            <div className="col">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="h5">
                        {t("Cart.title")}
                      </th>
                      <th scope="col">{t("Cart.category")}</th>
                      <th scope="col">{t("Cart.quantity")}</th>
                      <th scope="col">{t("Cart.price")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map(
                      (product: any, index: React.Key | null | undefined) => {
                        // Find the corresponding product in the `prods` array
                        const prod = prods.find(
                          (prod: any) => prod.id === product.id
                        );
                        
                        let prodCat = "";
                        prod?.categorie.forEach((c: any) => {
                          if (c.subCategory.length === 0) {
                            prodCat = c.title; 
                          }
                        });
                        

                        return (
                          <tr key={index}>
                            <th scope="row">
                              <div
                                className="d-flex  flex-column flex-md-row text-md-start"
                                style={{ width: "100%" }}
                              >
                                <img
                                  src={`https://api.faragroupe.fr/uploads/product_images/${prod.picture}`}
                                  className="img-fluid rounded mr-3"
                                  style={{ width: "120px" }}
                                  alt={prod.title}
                                />
                                <div className="d-flex flex-column ms-0 ms-md-4 mt-3 mt-md-0 align-items-start">
                                  <p className="mb-2">{prod.title}</p>
                                  <p className="mb-0">100g</p>
                                </div>
                              </div>
                            </th>
                            <td className="align-middle">
                              <p className="mb-0" style={{ fontWeight: 500 }}>
                                {prodCat}
                              </p>
                            </td>
                            <td className="align-middle">
                              <div className="d-flex flex-row">
                                <button
                                  className="btn btn-outline-light text-dark px-2"
                                  onClick={() =>
                                    handleQuantityChange(
                                      product.id,
                                      "stepDown",
                                      prod.price
                                    )
                                  }
                                >
                                  <i className="fas fa-minus"></i>
                                </button>

                                <input
                                  name="quantity"
                                  value={product.quantity}
                                  type="text"
                                  className="form-control form-control-sm"
                                  style={{ width: "45px" }}
                                  readOnly
                                />

                                <button
                                  className="btn btn-outline-light text-dark px-2"
                                  onClick={() =>
                                    handleQuantityChange(
                                      product.id,
                                      "stepUp",
                                      prod.price
                                    )
                                  }
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </td>
                            <td className="align-middle">
                              <p className="mb-0" style={{ fontWeight: 500 }}>
                                {prod.price * product.quantity} DH
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className="col-lg-4 col-xl-3 card shadow-2-strong mb-5 mb-lg-0 p-3 "
              style={{ height: "300px" }}
            >
              <div className="d-flex text-dark justify-content-between">
                <h3 className="mb-2">{t("Cart.sum")}</h3>
              </div>
              <hr className="my-2 " />
              <div
                className="d-flex justify-content-between"
                style={{ fontWeight: 500 }}
              >
                <h6 className="mb-2">{t("Cart.total")}</h6>
                <p className="mb-2">{currencyFormat(total)}</p>
              </div>

              <div
                className="d-flex justify-content-between"
                style={{ fontWeight: 500 }}
              >
                <h6 className="mb-0">{t("Cart.shipping")}</h6>
                <p className="mb-0">{currencyFormat(20)}</p>
              </div>

              <hr className="my-4" />

              <div
                className="d-flex justify-content-between mb-4"
                style={{ fontWeight: 500 }}
              >
                <h6 className="mb-2">{t("Cart.taxe")}</h6>
                <p className="mb-2">{currencyFormat(total + 20)}</p>
              </div>

              <button
                onClick={validateOrder}
                type="button"
                data-mdb-button-init
                data-mdb-ripple-init
                className="btn btn-primary btn-block btn-lg mt-auto"
              >
                <div className="d-flex justify-content-between">
                  <span>Validation</span>
                  <span>{currencyFormat(total + 20)}</span>
                </div>
              </button>
            </div>
          </div>

          <div
            id="shippingInfo"
            className="row  mt-2 ml-2"
            style={{ display: "none" }}
          >
            <div className="card mb-2" style={{ borderRadius: "16px" }}>
              <div className="card-header py-3">
                <h5 className="mb-0">Adresse de livraison</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="row mb-4">
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          onChange={handlePrenomChange}
                          type="text"
                          id="form7Example1"
                          className="form-control"
                          value={prenom}
                        />
                        <label className="form-label" htmlFor="form7Example1">
                          Prénom
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          onChange={handleNomChange}
                          type="text"
                          id="form7Example2"
                          className="form-control"
                          value={nom}
                        />
                        <label className="form-label" htmlFor="form7Example2">
                          Nom
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <select
                          name="Country"
                          className="form-control"
                          onChange={handlePaysChange}
                          required
                        >
                          <option value="" selected>
                            Choisir votre pays
                          </option>
                          <option value="United States">United States</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bosnia and Herzegovina">
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Bouvet Island">Bouvet Island</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Territory">
                            British Indian Ocean Territory
                          </option>
                          <option value="Brunei Darussalam">
                            Brunei Darussalam
                          </option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos (Keeling) Islands">
                            Cocos (Keeling) Islands
                          </option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Congo, The Democratic Republic of The">
                            Congo, The Democratic Republic of The
                          </option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote D'ivoire">Cote D'ivoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands (Malvinas)">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Territories">
                            French Southern Territories
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea-bissau">Guinea-bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Heard Island and Mcdonald Islands">
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value="Holy See (Vatican City State)">
                            Holy See (Vatican City State)
                          </option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran, Islamic Republic of">
                            Iran, Islamic Republic of
                          </option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea, Democratic People's Republic of">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="Korea, Republic of">
                            Korea, Republic of
                          </option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libyan Arab Jamahiriya">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macao">Macao</option>
                          <option value="Macedonia, The Former Yugoslav Republic of">
                            Macedonia, The Former Yugoslav Republic of
                          </option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia, Federated States of">
                            Micronesia, Federated States of
                          </option>
                          <option value="Moldova, Republic of">
                            Moldova, Republic of
                          </option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Netherlands Antilles">
                            Netherlands Antilles
                          </option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau">Palau</option>
                          <option value="Palestinian Territory, Occupied">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russian Federation">
                            Russian Federation
                          </option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="Saint Helena">Saint Helena</option>
                          <option value="Saint Kitts and Nevis">
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia">Saint Lucia</option>
                          <option value="Saint Pierre and Miquelon">
                            Saint Pierre and Miquelon
                          </option>
                          <option value="Saint Vincent and The Grenadines">
                            Saint Vincent and The Grenadines
                          </option>
                          <option value="Samoa">Samoa</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome and Principe">
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia and Montenegro">
                            Serbia and Montenegro
                          </option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="South Georgia and The South Sandwich Islands">
                            South Georgia and The South Sandwich Islands
                          </option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Svalbard and Jan Mayen">
                            Svalbard and Jan Mayen
                          </option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syrian Arab Republic">
                            Syrian Arab Republic
                          </option>
                          <option value="Taiwan, Province of China">
                            Taiwan, Province of China
                          </option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                          </option>
                          <option value="Thailand">Thailand</option>
                          <option value="Timor-leste">Timor-leste</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad and Tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks and Caicos Islands">
                            Turks and Caicos Islands
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="United States Minor Outlying Islands">
                            United States Minor Outlying Islands
                          </option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Viet Nam">Viet Nam</option>
                          <option value="Virgin Islands, British">
                            Virgin Islands, British
                          </option>
                          <option value="Virgin Islands, U.S.">
                            Virgin Islands, U.S.
                          </option>
                          <option value="Wallis and Futuna">
                            Wallis and Futuna
                          </option>
                          <option value="Western Sahara">Western Sahara</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                        <label className="form-label" htmlFor="form7Example1">
                          Pays
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <select
                          name="Country"
                          className="form-control"
                          onChange={handleRegionChange}
                          required
                        >
                          {" "}
                          <option value="" selected>
                            Choisir votre région
                          </option>
                          <option value="tanger-tetouan-al-hoceima">
                            Tanger-Tétouan-Al Hoceïma
                          </option>
                          <option value="oriental">Oriental</option>
                          <option value="fes-meknes">Fès-Meknès</option>
                          <option value="rabat-sale-kenitra">
                            Rabat-Salé-Kénitra
                          </option>
                          <option value="beni-mellal-khenifra">
                            Béni Mellal-Khénifra
                          </option>
                          <option value="casablanca-settat">
                            Casablanca-Settat
                          </option>
                          <option value="marrakech-safi">Marrakech-Safi</option>
                          <option value="draa-tafilalet">Drâa-Tafilalet</option>
                          <option value="souss-massa">Souss-Massa</option>
                          <option value="guelmim-oued-noun">
                            Guelmim-Oued Noun
                          </option>
                          <option value="laayoune-sakia-el-hamra">
                            Laâyoune-Sakia El Hamra
                          </option>
                          <option value="dakhla-oued-ed-dahab">
                            Dakhla-Oued Ed-Dahab
                          </option>
                        </select>
                        <label className="form-label" htmlFor="form7Example1">
                          Région
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          onChange={handleCodePostalChange}
                          type="text"
                          id="form7Example1"
                          className="form-control"
                          required
                        />
                        <label className="form-label" htmlFor="form7Example1">
                          Code postal
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          onChange={handleRueInfoChange}
                          type="text"
                          id="form7Example2"
                          className="form-control"
                          required
                        />
                        <label className="form-label" htmlFor="form7Example2">
                          Rue
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          onChange={handleEmailChange}
                          type="text"
                          id="form7Example1"
                          className="form-control"
                          value={email}
                          required
                        />
                        <label className="form-label" htmlFor="form7Example1">
                          Adresse E-mail
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          onChange={handleTelephoneChange}
                          type="text"
                          id="form7Example2"
                          className="form-control"
                          value={telephone}
                        />
                        <label className="form-label" htmlFor="form7Example2">
                          Téléphone
                        </label>
                      </div>
                    </div>
                  </div>

                  <div data-mdb-input-init className="form-outline mb-2">
                    <textarea
                      className="form-control"
                      id="form7Example7"
                      rows={4}
                    ></textarea>
                    <label className="form-label" htmlFor="form7Example7">
                      Informations additionnel
                    </label>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="card shadow-2-strong mb-5 mb-lg-0"
              style={{ borderRadius: "16px" }}
            >
              <div className="card-header">
                <h5 className="mb-0">Mode de payement</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6  mb-4 mb-md-0">
                    <form>
                      <div
                        className={`d-flex flex-row pb-3 ${selectedOption === "credit" ? "selected" : ""}`}
                        onClick={() => handleOptionChange("credit")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="rounded border w-100 p-3"
                        >
                          <p className="d-flex align-items-center mb-0">
                            <i className="fab fa-cc-mastercard fa-2x text-body pe-2 mr-2"></i>
                            {t("credit")}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`d-flex flex-row pb-3 ${selectedOption === "debit" ? "selected" : ""}`}
                        onClick={() => handleOptionChange("debit")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="rounded border w-100 p-3"
                        >
                          <p className="d-flex align-items-center mb-0">
                            <i className="fab fa-cc-visa fa-2x fa-lg text-body pe-2 mr-2"></i>
                            {t("debit")}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`d-flex flex-row ${selectedOption === "paypal" ? "selected" : ""}`}
                        onClick={() => handleOptionChange("paypal")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="rounded border w-100 p-3"
                        >
                          <p className="d-flex align-items-center mb-0">
                            <i className="fab fa-cc-paypal fa-2x fa-lg text-body pe-2 mr-2"></i>
                            PayPal
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/* credit card */}
                  <div className="col-md-6 col-lg-4 col-xl-6" id="credit">
                    <div className="row">
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeName"
                            className="form-control form-control-lg"
                            placeholder="John Smith"
                          />
                          <label className="form-label" htmlFor="typeName">
                            {t("name")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeExp"
                            className="form-control form-control-lg"
                            placeholder="MM/YY"
                            size={7}
                          />
                          <label className="form-label" htmlFor="typeExp">
                            {t("expiry")}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeText"
                            className="form-control form-control-lg"
                            placeholder="1111 2222 3333 4444"
                            size={17}
                          />
                          <label className="form-label" htmlFor="typeText">
                            {t("number")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="password"
                            id="typeCvv"
                            className="form-control form-control-lg"
                            placeholder="●●●"
                            size={3}
                          />
                          <label className="form-label" htmlFor="typeCvv">
                            CVV
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* debit card */}
                  <div
                    id="debit"
                    className="col-md-6 col-lg-4 col-xl-6"
                    style={{ display: "none" }}
                  >
                    <div className="row">
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeName"
                            className="form-control form-control-lg"
                            placeholder="John Smith"
                          />
                          <label className="form-label" htmlFor="typeName">
                            {t("name")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeExp"
                            className="form-control form-control-lg"
                            placeholder="MM/YY"
                            size={7}
                          />
                          <label className="form-label" htmlFor="typeExp">
                            {t("expiry")}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeText"
                            className="form-control form-control-lg"
                            placeholder="1111 2222 3333 4444"
                            size={17}
                          />
                          <label className="form-label" htmlFor="typeText">
                            {t("number")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="password"
                            id="typeCvv"
                            className="form-control form-control-lg"
                            placeholder="●●●"
                            size={3}
                          />
                          <label className="form-label" htmlFor="typeCvv">
                            CVV
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* paypal */}
                  <div
                    id="paypal"
                    className="col-md-6 col-lg-4 col-xl-6"
                    style={{ display: "none" }}
                  >
                    <div className="form-outline mb-4 mb-xl-5">
                      <input
                        type="email"
                        id="paypalEmail"
                        className="form-control form-control-lg"
                        placeholder="email@example.com"
                      />
                      <label className="form-label" htmlFor="paypalEmail">
                        Email PayPal
                      </label>
                    </div>
                    <p>{t("paypalPay")}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-right m-2">
                <button
                  type="submit"
                  className="btn btn-primary btn-md px-5"
                  onClick={checkOut}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ShoppingCart;
