import React, { useContext, useEffect, useState } from "react";
import "../App.css";
// import "./header.css"
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUploads } from "../process";
import i18n from "../i18n";
import { reloadResources, t } from "i18next";
import { GlobalContext } from "../App";
interface HeaderProps {
  categoriesList: any[];
  sub: any[];
}

const Header: React.FC<HeaderProps> = ({ categoriesList, sub }) => {
 
  const cart = useContext(GlobalContext).cart;
  const setCart = useContext(GlobalContext).setCart;
  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
    
  };
  const [isSticky, setSticky] = useState<boolean>(false);
  const storedUserInfo = JSON.parse(localStorage.getItem("user-info") || "{}");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <style>
        {`
      body, section {
        margin: 0;
        padding: 0;
      }
    `}
      </style>

      <nav
        className={`text-dark navbar navbar-expand-lg bg-light navbar-light pl-1 ${isSticky ? "sticky-top" : ""}`}
        style={{ width: "100%" }}
      >
        <div className="container-fluid p-0">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="mr-4">
            <Link to="/">
              <img
                className="img-fluid rounded-circle"
                src="http://localhost:3000/favicon.ico"
                alt="FaraGroupe"
                style={{ width: "50px", height: "50px" }}
              />
            </Link>
          </div>

          <div className="collapse navbar-collapse " id="navbarNav">
            <div className="row navbar-nav d-flex align-items-center pl-3">
              {categoriesList.map((cat) => (
                  <div key={cat.id} className="item dropdown " style={{fontSize:"15px"}}>
                    <Link
                      className="nav-link  "
                      to="/produits"
                      id={`dropdownMenu${cat.id}`}

                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {cat.title}
                    </Link>
                    {sub.filter((subCat) => subCat.parent.title === cat.title).length !== 0 ? 
                     <div className="dropdown-content">
                     <div className="image-list">
                       {sub
                         .filter((subCat) => subCat.parent.title === cat.title)
                         .map((subCat,index) => (
                           <div key={index} className="image-item ">
                             <Link to="./produits">
                               <img
                                 className="img-fluid"
                                 src={`${apiUploads}/category_images/${subCat.picture}`}
                                 alt={subCat.title}
                               />
                             </Link>
                             <span>{subCat.title}</span>
                             {/* <Link
                               className="nav-link text-center"
                               to="./produits"
                             >
                               {subCat.title}
                             </Link>
                             <span className="tooltip">{subCat.title}</span>  */}
                           </div>
                         ))}
                     </div>
                   </div> : ""
                          }
                   
                  
                  </div>
                ))}
            </div>
          </div>

        

          {/* User Profile and Cart */}
          <div className="d-flex justify-content-end">
            <Link to="/like" className="mr-3 ml-2">
            <i className="fa-solid text-red fa-heart"></i>
            </Link>
            
            <Link  className="text-dark" to={
                localStorage.getItem("user-info")
                  ? `/profile/${storedUserInfo.id}`
                  : "/login"
              }><i className="fa-solid fa-user"></i></Link>
            <Link
              to="/cart"
              
              className="btn cart-container btn-lg btn-outline-light text-dark"
            >
              <i className="fa fa-cart-shopping"></i>
              <span className="notification-badge">{cart.length}</span>
            </Link> <div className="btn-group">
            <a
              className="btn btn-outline-white nav-link text-dark dropdown-toggle"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              ☰
            </a>
            <div
              className="dropdown-menu dropdown-menu-right bg-light "
              aria-labelledby="dropdownMenuButton"
            >
              <div className="d-flex nav-link">
                <button
                  className="btn nav-link btn-sm rounded-circle"
                  onClick={() => changeLanguage("en")}
                >
                  <img
                    style={{ width: "18px", height: "18px" }}
                    src="https://cdn-icons-png.flaticon.com/256/197/197374.png"
                    alt="EN"
                  />
                </button>
                <button
                  className="btn nav-link btn-sm rounded-circle"
                  onClick={() => changeLanguage("fr")}
                >
                  <img
                    style={{ width: "18px", height: "18px" }}
                    src="https://cdn-icons-png.flaticon.com/256/5921/5921991.png"
                    alt="FR"
                  />
                </button>
              </div>
              <Link to="/apropos" className="ml-2 nav-link">
                <i className="mr-3 fa-solid fa-circle-info"></i>
                {t("about.header")}
              </Link>
            </div>
          </div>
          </div>

          {/* Language Switcher */}
         
        </div>
      </nav>



    </>
  );
};

export default Header;
