import React, { createContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Register from "./Components/Register";
import Prods from "./Components/Prods";
import ShoppingCart from "./Components/cart";
import AboutPage from "./Components/Apropos";
import Details from "./Components/Details";
import Profile from "./Components/Profile";
import Header from "./Components/Header"; 
import axios from "axios";
import { apiUrl } from "./process";
import Wishlist from "./Components/wishlist";
import { useTranslation } from "react-i18next";



export const GlobalContext = createContext<any>(null);

const App: React.FC = ({ children }: any) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [cart, setCart] = useState([]);
  const [sub, setSub] = useState<any[]>([]);
  const [categoriesList, setCategoriesList] = useState<any[]>([]);
  const [pr,setProds] = useState<any[]>([]);
  const [favorites,setFavorites] = useState<any []>();
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/categories.json`);
      const categories = response.data;
      const filteredCategories = categories.filter(
        (e: { parent: any }) => e.parent == null
      );
      
      const subCategories = categories.filter(
        (e: { parent: any }) => e.parent != null
      );



      const titles = filteredCategories.map(
        (element: { id: string; title: string; picture: string }) => ({
          id: element.id,
          title: element.title,
          picture: element.picture,
        })
      );
     
      setSub(subCategories);
      setCategoriesList(titles);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchProds = async () => {
    try {
      const response = await axios.get(`https://api.faragroupe.fr/api/products.json`);  
      setProds(response.data);
        
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    
  };

  const fetchFavs = async () => {
    try {
      const response = await axios.get(`https://api.faragroupe.fr/api/favorites`);
      setFavorites(response.data)
      
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    
  };
  
  useEffect(() => {
    fetchCategories();
    fetchFavs();
    fetchProds();
    const toggleVisibility = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
    
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  

  return (
    <GlobalContext.Provider value={{ pr, cart, setCart ,favorites}}>
      <>
        <Header categoriesList={categoriesList} sub={sub} />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/produits"
            element={<Prods prods = {pr} categoriesList={categoriesList} sub={sub} />}
          />
          <Route path="/cart" element={<ShoppingCart />} />
          <Route path="/apropos" element={<AboutPage />} />
          <Route path="/details/:id" element={<Details />} />
          <Route
            path="/produits/:cat"
            element={<Prods categoriesList={categoriesList} sub={sub} prods={pr} />}
          />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/like" element={<Wishlist  />} />
          
        </Routes>
      
      </>
      <span>
     
     <button 
       onClick={handleScrollToTop} 
       className="btn btn-light back-to-top"
       style={{ display: isVisible ? "block" : "none" }}
     >
       <i className="fa-solid fa-up-long"></i> 
     </button>
   </span>
    </GlobalContext.Provider>
  );
};

export default App;



