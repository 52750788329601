

document.addEventListener('DOMContentLoaded', function() {
     let animatedElements = document.querySelectorAll('.fadeIn');
     
     function fadeInElements() {
         animatedElements.forEach(element => {
             if (isElementInViewport(element) && !element.classList.contains('animated')) {
                 element.classList.add('animated');
             }
         });
     }
 
     function isElementInViewport(el) {
         const rect = el.getBoundingClientRect();
         return (
             rect.top >= 0 &&
             rect.left >= 0 &&
             rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
             rect.right <= (window.innerWidth || document.documentElement.clientWidth)
         );
     }
 
     window.addEventListener('scroll', fadeInElements);
     window.addEventListener('resize', fadeInElements);
 
     // Trigger the animation on page load if elements are initially in view
     fadeInElements();
 });

 